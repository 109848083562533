import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CalcOnlyAviaPackageRequest,
  CalcOnlyAviaPackageResponse,
  SearchOnlyAviaPackagesRequest,
  SearchOnlyAviaPackagesResponse
} from '@appTypes/api.types';
import { GetBestOffersResponse, GetRecentSearchesResponse } from '@appTypes/redefined-types';
import { environment } from '@env/environment';
import { CatalogueApiService } from '@shared/services/api/catalogue-api.service';

@Injectable()
export class AviaApiService {
  private readonly apiEndPoint: string = environment.urls.api + '/OnlyAviaPackages/';
  private readonly mockupsApiEndPoint: string = 'assets/_api_mockups_/Flights/';

  constructor(
    private httpClient: HttpClient,
    private catalogueApiService: CatalogueApiService
  ) {}

  public getRecentSearches() {
    return this.httpClient.get<GetRecentSearchesResponse>(this.mockupsApiEndPoint + 'GetRecentSearchs.json');
  }

  public getBestOffers() {
    return this.httpClient.get<GetBestOffersResponse>(this.mockupsApiEndPoint + 'GetBestOffers.json');
  }

  public getAirports() {
    return this.catalogueApiService.getAirportTree();
  }

  public searchOnlyAviaPackages(payload: SearchOnlyAviaPackagesRequest) {
    return this.httpClient.post<SearchOnlyAviaPackagesResponse>(this.apiEndPoint + 'SearchOnlyAviaPackages', payload);
  }

  public calcPackage(payload?: CalcOnlyAviaPackageRequest) {
    return this.httpClient.post<CalcOnlyAviaPackageResponse>(this.apiEndPoint + 'CalcPackage', {
      ...payload
    });
  }
}
