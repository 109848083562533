import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlightRequest } from '@appTypes/api.types';
import { AirportTreeItem } from '@appTypes/redefined-types';
import { StoreService } from '@core/store/store.service';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { AVIA_TYPES_STATIC_DATA, NGXS_AVIA_SEARCH_FORM } from '@pages/avia-page/avia-page.const';
import { aviaGetAirports, aviaResetSearchResult, aviaSearchPackages } from '@pages/avia-page/state/avia.actions';
import { AviaState } from '@pages/avia-page/state/avia.state';
import { FlightTicketTypeValues, TicketTypesEnum } from '../avia-page.types';
import { minDate, minDateThanField, requiredIf } from '@core/misc/validators.utils';
import { environment } from '@env/environment';
import { appSetSellCurrency } from 'src/app/state/app.actions';

@Injectable()
export class AviaSearchFormService {
  public formStatus$ = this.storeService.select(AviaState.searchLoadStatus);

  /* @note - flightTypes is hidden on this version */
  public flightTypes: typeof AVIA_TYPES_STATIC_DATA = [];
  //public flightTypes = AVIA_TYPES_STATIC_DATA;
  /*  */
  public today = new Date();
  public childrenAgesData = Array.from(Array(environment.childMaxAge).keys()).map((i) => {
    return { value: i + 1, label: `${i + 1}` };
  });
  public isSubmited = false;
  public readonly ngxsForm = NGXS_AVIA_SEARCH_FORM;
  public readonly formGroup = new FormGroup({
    flightRequests: new FormArray<
      FormGroup<{
        arrivalLocation: FormControl<string | null>;
        arrivalType: FormControl<string | null>;
        departureDate: FormControl<Date | null>;
        departureLocation: FormControl<string | null>;
        departureType: FormControl<string | null>;
        returnDate: FormControl<Date | null>;
      }>
    >([]),
    adults: new FormControl<number>(1),
    children: new FormControl<number>(0),
    childrenAges: new FormArray<FormControl<number | null>>([]),
    _flightTicketType: new FormControl<FlightTicketTypeValues>('roundTrip'),
    ticketType: new FormControl<TicketTypesEnum | null>(TicketTypesEnum['Coach'])
  });

  get activedFlightType() {
    return this.formGroup.controls._flightTicketType.value;
  }
  set activedFlightType(value) {
    this.formGroup.controls._flightTicketType.setValue(value);
  }

  public readonly maxFlightRequests = 4;

  get flightRequests() {
    return this.formGroup.controls.flightRequests.controls;
  }
  get childrenAges() {
    return this.formGroup.controls.childrenAges.controls;
  }

  public airportsTypedToken!: AirportTreeItem;
  public airports$ = this.storeService.select(AviaState.airports);

  public get hasChildrenAgesError() {
    if (!this.formGroup.controls.childrenAges.controls.length) return false;
    return this.formGroup.controls.childrenAges.controls.some((e) => e.errors);
  }

  public isInit = false;

  constructor(private storeService: StoreService) {}

  public init() {
    this.storeService.dispatch(new appSetSellCurrency('avia', 'USD'));

    if (this.isInit) return;
    this.addFlightRequest();
    this.initFormData();
    this.isInit = true;
  }
  private initFormData() {
    this.storeService.dispatch(new aviaGetAirports());

    const storedFormData = this.storeService.selectSnapshot(AviaState.searchFormData);

    if (storedFormData?.childrenAges?.length)
      for (const iterator of storedFormData.childrenAges)
        this.formGroup.controls.childrenAges.push(new FormControl<number | null>(iterator, Validators.required));

    if (storedFormData?.flightRequests?.length) {
      this.formGroup.controls.flightRequests.clear();
      for (const iterator of storedFormData.flightRequests)
        this.formGroup.controls.flightRequests.push(this.flightRequestForm(iterator));
    }
  }
  public onAddChildren(children: number) {
    const current = this.formGroup.controls.childrenAges.length;
    if (current < children)
      for (let index = 0; index < children - current; index++)
        this.formGroup.controls.childrenAges.push(new FormControl<number | null>(null, Validators.required));
    else if (current > children)
      for (let index = children; index < current; index++) this.formGroup.controls.childrenAges.removeAt(index);
  }
  public onChangeFlightType(type: FlightTicketTypeValues): void {
    if (type === this.activedFlightType) return;
    if (type === 'multipleFlights') {
      if (this.formGroup.controls.flightRequests.controls[0].controls.returnDate.hasValidator(Validators.required))
        this.formGroup.controls.flightRequests.controls[0].controls.returnDate.removeValidators(Validators.required);
      this.onAddFlightRequest();
    } else {
      this.activedFlightType = type;
      const flightRequests = this.formGroup.controls.flightRequests;
      if (flightRequests.length > 1) {
        const storeFirstToKeep = flightRequests.controls[0];
        flightRequests.clear();
        flightRequests.push(storeFirstToKeep);
      }
      //updateValidators
      for (let index = 0; index < flightRequests.controls.length; index++) {
        if (type === 'roundTrip') flightRequests.controls[index].controls.returnDate.addValidators(Validators.required);
        else {
          flightRequests.controls[index].controls.returnDate.setValue(null);
          flightRequests.controls[index].controls.returnDate.removeValidators(Validators.required);
        }
        flightRequests.controls[index].controls.returnDate.updateValueAndValidity();
      }
    }
  }
  public onAddFlightRequest() {
    if (this.activedFlightType !== 'multipleFlights') this.activedFlightType = 'multipleFlights';
    this.addFlightRequest();
  }
  private addFlightRequest() {
    if (this.isSubmited) this.isSubmited = false;
    this.formGroup.controls.flightRequests.push(this.flightRequestForm());
  }

  private flightRequestForm(values?: FlightRequest) {
    return new FormGroup({
      arrivalLocation: new FormControl<string | null>(values?.arrivalLocation ?? null, Validators.required),
      arrivalType: new FormControl<string | null>(values?.arrivalType ?? null),
      departureDate: new FormControl<Date | null>(values?.departureDate ?? null, [
        Validators.required,
        minDate(undefined, 'Date must be after today')
      ]),
      departureLocation: new FormControl<string | null>(values?.departureLocation ?? null, Validators.required),
      departureType: new FormControl<string | null>(values?.departureType ?? null),
      returnDate: new FormControl<Date | null>(values?.returnDate ?? null, [
        requiredIf(this.formGroup.controls._flightTicketType, 'roundTrip'),
        minDateThanField('departureDate', 'Return Date must be after departure date')
      ])
    });
  }

  public resetForm() {
    this.formGroup.reset({
      flightRequests: [],
      adults: 1,
      children: 0,
      childrenAges: [],
      ticketType: TicketTypesEnum['Coach']
    });
    this.storeService.dispatch(new UpdateFormValue({ path: this.ngxsForm, value: this.formGroup.getRawValue() }));
    setTimeout(() => {
      this.storeService.dispatch(new aviaResetSearchResult());
    }, 100);
    this.isSubmited = false;
  }
  public onSwapFlightRequestAirports(index: number) {
    const fromValue = this.formGroup.controls.flightRequests.controls[index].controls.departureLocation.value;
    const toValue = this.formGroup.controls.flightRequests.controls[index].controls.arrivalLocation.value;
    /*  */
    this.formGroup.controls.flightRequests.controls[index].controls.departureLocation.setValue(toValue);
    this.formGroup.controls.flightRequests.controls[index].controls.arrivalLocation.setValue(fromValue);
  }
  public onSubmit() {
    if (!this.isSubmited) this.isSubmited = true;
    if (this.formGroup.invalid) return;

    this.storeService.dispatch(new UpdateFormValue({ path: this.ngxsForm, value: this.formGroup.getRawValue() }));
    this.storeService.dispatch(new aviaSearchPackages());
    this.isSubmited = false;
  }
  public onRemoveFlighRequest(index: number) {
    this.formGroup.controls.flightRequests.removeAt(index);
  }
}
